<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          class="kunde-avatar"
          ref="previewEl"
          :src="imageSrc"
          :text="`${userData.Firma.charAt()}`"
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">{{ userData.Firma }}</h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            accept="image/*"
            class="d-none"
            @input="setNewImage"
          />
          <span class="d-none d-sm-inline">Bild Aktualisieren</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">Bild Entfernen</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <validation-observer ref="updateCustomerForm" #default="{ invalid }">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <b-col cols="12">
            <h4>Anmeldedaten</h4>
          </b-col>
          <!-- Field: Username -->
          <b-col cols="12" md="4">
            <b-form-group label="Username" label-for="username">
              <validation-provider
                #default="{ errors }"
                name="Username"
                vid="username"
                rules="required|min:5"
              >
                <b-form-input
                  id="username"
                  placeholder="firma"
                  v-model="userData.User.username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="userIdNotUnique" class="text-danger"
                  >Benutzername bereits vergeben</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" md="4">
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.User.email"
                  type="email"
                  placeholder="info@firma.de"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="emailNotUnique" class="text-danger"
                  >Email-Adresse bereits vergeben</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <!-- Passwort -->
            <b-form-group label-for="password" label="Passwort">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="min:6"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="password"
                    v-model="userData.User.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Grunddaten</h4>
          </b-col>

          <!-- Field: Firma -->
          <b-col cols="12" md="4">
            <b-form-group label="Firma" label-for="firma">
              <validation-provider
                #default="{ errors }"
                name="Firma"
                vid="firma"
                rules="required"
                placeholder="Firma"
              >
                <b-form-input id="firma" v-model="userData.Firma" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: USt.-ID -->
          <b-col cols="12" md="4">
            <b-form-group label="USt.-ID" label-for="ust-id">
              <b-form-input id="ust-id" v-model="userData.USt_ID" />
            </b-form-group>
          </b-col>

          <!-- Field: Webseite -->
          <b-col cols="12" md="4">
            <b-form-group label="Webseite" label-for="webseite">
              <b-form-input id="webseite" v-model="userData.Webseite" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Anschrift</h4>
          </b-col>

          <!-- Field: Straße -->
          <b-col cols="12" md="4">
            <b-form-group label="Straße" label-for="strasse">
              <b-form-input id="strasse" v-model="userData.Anschrift.Strasse" />
            </b-form-group>
          </b-col>

          <!-- Field: Hausnummer -->
          <b-col cols="12" md="4">
            <b-form-group label="Hausnummer" label-for="hausnummer">
              <b-form-input
                id="hausnummer"
                v-model="userData.Anschrift.Hausnummer"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Adresszusatz -->
          <b-col cols="12" md="4">
            <b-form-group label="Adresszusatz" label-for="adresszusatz">
              <b-form-input
                id="adresszusatz"
                v-model="userData.Anschrift.Zusatz"
              />
            </b-form-group>
          </b-col>

          <!-- Field: PLZ -->
          <b-col cols="12" md="4">
            <b-form-group label="PLZ" label-for="plz">
              <b-form-input id="plz" v-model="userData.Anschrift.PLZ" />
            </b-form-group>
          </b-col>

          <!-- Field: Stadt -->
          <b-col cols="12" md="4">
            <b-form-group label="Stadt" label-for="stadt">
              <b-form-input id="stadt" v-model="userData.Anschrift.Stadt" />
            </b-form-group>
          </b-col>

          <!-- Field: Rechnungsadresse hinzufügen -->
          <b-col cols="12" v-if="showInvoiceSwitch">
            <b-form-checkbox
              v-model="showInvoiceAddress"
              name="check-button"
              switch
              inline
            >
              Abweichende Rechnungsanschrift hinzufügen?
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row v-if="userData.Rechnungsanschrift || showInvoiceAddress">
          <b-col cols="12" class="mt-1">
            <h4>Rechnungsanschrift</h4>
          </b-col>

          <!-- Field: Straße -->
          <b-col cols="12" md="4">
            <b-form-group label="Straße" label-for="rechnungsanschrift-strasse">
              <b-form-input
                id="rechnungsanschrift-strasse"
                v-model="userData.Rechnungsanschrift.Strasse"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Hausnummer -->
          <b-col cols="12" md="4">
            <b-form-group
              label="Hausnummer"
              label-for="rechnungsanschrift-hausnummer"
            >
              <b-form-input
                id="rechnungsanschrift-hausnummer"
                v-model="userData.Rechnungsanschrift.Hausnummer"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Adresszuastz -->
          <b-col cols="12" md="4">
            <b-form-group
              label="Adresszuastz"
              label-for="rechnungsanschrift-adresszusatz"
            >
              <b-form-input
                id="rechnungsanschrift-adresszusatz"
                v-model="userData.Rechnungsanschrift.Zuastz"
              />
            </b-form-group>
          </b-col>

          <!-- Field: PLZ -->
          <b-col cols="12" md="4">
            <b-form-group label="PLZ" label-for="rechnungsanschrift-plz">
              <b-form-input
                id="rechnungsanschrift-plz"
                v-model="userData.Rechnungsanschrift.PLZ"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Stadt -->
          <b-col cols="12" md="4">
            <b-form-group label="Stadt" label-for="rechnungsanschrift-stadt">
              <b-form-input
                id="rechnungsanschrift-stadt"
                v-model="userData.Rechnungsanschrift.Stadt"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Kontakt</h4>
          </b-col>

          <!-- Field: Telefon -->
          <b-col cols="12" md="4">
            <b-form-group label="Telefon" label-for="telefon">
              <b-form-input
                id="telefon"
                v-model="userData.Kontakt.Telefon"
                placeholder="1234 12345678"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Mobil -->
          <b-col cols="12" md="4">
            <b-form-group label="Mobil" label-for="mobil">
              <b-form-input
                id="mobil"
                v-model="userData.Kontakt.Mobil"
                placeholder="1234 12345678"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Fax -->
          <b-col cols="12" md="4">
            <b-form-group label="Fax" label-for="fax">
              <b-form-input
                id="fax"
                v-model="userData.Kontakt.Fax"
                placeholder="1234 12345678"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Ansprechpartner</h4>
          </b-col>

          <!-- Ansprechpartner -->
          <b-col
            cols="12"
            md="4"
            class="mb-1"
            v-for="(ansprechpartner, index) in userData.Ansprechpartner"
            :key="ansprechpartner.id"
          >
            <b-card class="mb-0 position-relative" bg-variant="light-secondary">
              <b-button
                v-if="ansprechpartner.new"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-delete-contact-person position-absolute"
                @click="deleteContactPerson(index)"
              >
                <feather-icon icon="TrashIcon" class="mr-25" />
              </b-button>

              <!-- Ansprechpartner Vorname-->
              <b-form-group
                label="Vorname"
                :label-for="`ansprechpartner-${ansprechpartner.id}-vorname`"
              >
                <b-form-input
                  :id="`ansprechpartner-${ansprechpartner.id}-vorname`"
                  v-model="ansprechpartner.Vorname"
                  placeholder="Max"
                />
              </b-form-group>

              <!-- Ansprechpartner Nachname-->
              <b-form-group
                label="Nachname"
                :label-for="`ansprechpartner-${ansprechpartner.id}-nachname`"
              >
                <b-form-input
                  :id="`ansprechpartner-${ansprechpartner.id}-nachname`"
                  v-model="ansprechpartner.Nachname"
                  placeholder="Mustermann"
                />
              </b-form-group>

              <!-- Ansprechpartner Abteilung-->
              <b-form-group
                label="Abteilung"
                :label-for="`ansprechpartner-${ansprechpartner.id}-abteilung`"
              >
                <b-form-input
                  :id="`ansprechpartner-${ansprechpartner.id}-abteilung`"
                  v-model="ansprechpartner.Abteilung"
                  placeholder="Abteilung"
                />
              </b-form-group>

              <!-- Ansprechpartner Position-->
              <b-form-group
                label="Position"
                :label-for="`ansprechpartner-${ansprechpartner.id}-position`"
              >
                <b-form-input
                  :id="`ansprechpartner-${ansprechpartner.id}-position`"
                  v-model="ansprechpartner.Position"
                  placeholder="Position"
                />
              </b-form-group>

              <!-- Ansprechpartner Telefon-->
              <b-form-group
                label="Telefon"
                :label-for="`ansprechpartner-${ansprechpartner.id}-telefon`"
              >
                <b-form-input
                  :id="`ansprechpartner-${ansprechpartner.id}-telefon`"
                  v-model="ansprechpartner.Telefon"
                  placeholder="1234 1234567"
                />
              </b-form-group>

              <!-- Ansprechpartner Mobil-->
              <b-form-group
                label="Mobil"
                :label-for="`ansprechpartner-${ansprechpartner.id}-mobil`"
              >
                <b-form-input
                  :id="`ansprechpartner-${ansprechpartner.id}-mobil`"
                  v-model="ansprechpartner.Mobil"
                  placeholder="1234 1234567"
                />
              </b-form-group>

              <!-- Ansprechpartner Email-->
              <b-form-group
                label="Email"
                :label-for="`ansprechpartner-${ansprechpartner.id}-email`"
              >
                <b-form-input
                  :id="`ansprechpartner-${ansprechpartner.id}-email`"
                  v-model="ansprechpartner.Email"
                  placeholder="max@firma.de"
                />
              </b-form-group>

              <!-- Ansprechpartner Inaktiv-->
              <b-form-group
                label="Inaktiv"
                :label-for="`ansprechpartner-${ansprechpartner.id}-inaktiv`"
              >
                <b-form-checkbox
                  :id="`ansprechpartner-${ansprechpartner.id}-inaktiv`"
                  v-model="ansprechpartner.Inaktiv"
                  >Ansprechpartner ist inaktiv</b-form-checkbox
                >
              </b-form-group>
            </b-card>
          </b-col>

          <b-col cols="12" md="4" class="mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addContactPerson"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Ansprechpartner hinzufügen</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Sonstiges</h4>
          </b-col>

          <!-- Field: Zahlungsziel -->
          <b-col cols="12" md="4">
            <b-form-group label="Zahlungsziel" label-for="zahlungsziel">
              <b-input-group append="Tage">
                <b-form-input
                  id="zahlungsziel"
                  v-model="userData.Zahlungsziel"
                  placeholder="Zahlungsziel in Tagen"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Bewertung -->
          <b-col cols="12" md="4">
            <b-form-group label="Bewertung" label-for="bewertung">
              <b-form-radio-group
                id="bewertung"
                class="d-flex justify-content-between mt-1"
                v-model="userData.Bewertung"
                :options="[
                  { text: '0', value: 0 },
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 },
                  { text: '6', value: 6 },
                ]"
                name="radio-validation"
              >
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <!-- Sonstiges -->
          <b-col cols="12">
            <b-form-group label="Sonstige Informationen" label-for="sonstiges">
              <b-form-textarea
                id="sonstiges"
                v-model="userData.Sonstiges"
                rows="3"
                name="sonstiges"
                placeholder="Sonstige Informationen"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateCustomer"
    >
      Änderungen Speichern
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="abort"
    >
      Abbrechen
    </b-button>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BFormCheckbox,
  BFormRadioGroup,
  BFormTextarea,
} from 'bootstrap-vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: {
    BCard,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: null,
      imageSrc: '',
      showInvoiceSwitch: false,
      showInvoiceAddress: false,
      customerIdNotUnique: false,
      userIdNotUnique: false,
      emailNotUnique: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  watch: {
    'userData.Kundennummer': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              Kundennummer: {
                $eq: this.userData.Kundennummer,
              },
            },
            fields: ['id', 'Kundennummer'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/kunden?${query}`)
          .then(response => {
            if (response.data.data.length > 0) {
              this.customerIdNotUnique = true;
            } else {
              this.customerIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    'userData.User.username': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              username: {
                $eq: this.userData.User.username,
              },
            },
            fields: ['id', 'username'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/users?${query}`)
          .then(response => {
            if (response.data.length > 0) {
              this.userIdNotUnique = true;
            } else {
              this.userIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    'userData.User.email': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              email: {
                $eq: this.userData.User.email,
              },
            },
            fields: ['id', 'email'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/users?${query}`)
          .then(response => {
            if (response.data.length > 0) {
              this.emailNotUnique = true;
            } else {
              this.emailNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    showInvoiceAddress(newValue) {
      if (newValue) {
        this.userData.Rechnungsanschrift = {};
      }

      if (!newValue) {
        this.userData.Rechnungsanschrift = null;
      }
    },
  },
  methods: {
    setNewImage() {
      this.image = this.$refs.refInputEl.files[0];
      base64Encode(this.image)
        .then(value => {
          this.imageSrc = value;
        })
        .catch(() => {
          this.imageSrc = '';
        });
    },
    addContactPerson() {
      this.userData.Ansprechpartner.push({
        Vorname: '',
        Nachname: '',
        Abteilung: '',
        Telefon: '',
        Email: '',
        new: true,
      });
    },
    deleteContactPerson(index) {
      this.userData.Ansprechpartner.splice(index, 1);
    },
    async updateCustomer() {
      this.$refs.updateCustomerForm.validate().then(async isValid => {
        if (!isValid) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }
        // Set Customer Data
        await this.$http
          .put(`/users/${this.userData.User.id}`, {
            username: this.userData.User.username,
            email: this.userData.User.email,
            password: this.userData.User.password,
          })
          .then(async () => {
            // Set Image
            if (this.image) {
              if (this.userData.User.avatar?.id) {
                await this.$http
                  .delete(`/upload/files/${this.userData.User.avatar.id}`)
                  .catch(err => console.log(err.response.data.error.message));
                this.userData.User.avatar = null;
              }

              const formData = new FormData();
              formData.append('field', 'avatar');
              formData.append('ref', 'plugin::users-permissions.user');
              formData.append('refId', this.userData.User.id);
              formData.append('files', this.image, this.image.name);

              await this.$http
                .post('/upload', formData)
                .catch(err => console.log(err.response.data.error.message));
            }

            await this.$http
              .put(`/kunden/${this.userData.id}`, { data: this.userData })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Kunde aktualisiert`,
                    icon: 'SaveIcon',
                    variant: 'success',
                    text: `Der Kunde ${this.userData.Firma} wurde erfolgreich aktualisiert.`,
                  },
                });

                this.$router.push({
                  name: 'kunde-ansicht',
                  params: { id: this.$route.params.id },
                });
              })
              .catch(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Aktualisierung des Kunden fehlgeschlagen!`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: `Der Kunde konnte nicht aktualisiert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
                  },
                });
                console.log(err.response.data.error.message);
              });
          })
          .catch(err => console.log(err));
      });
    },
    abort() {
      this.$router.push({
        name: 'kunde-ansicht',
        params: { id: this.$route.params.id },
      });
    },
  },
  beforeMount() {
    if (!this.userData.Rechnungsanschrift) {
      this.showInvoiceSwitch = true;
    }

    if (this.userData.User.avatar) {
      this.imageSrc =
        this.$dbBaseUrl + this.userData.User.avatar.formats.thumbnail.url;
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.checkbox-group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.checkbox-group .custom-checkbox {
  width: 10rem;
  margin-bottom: 0.5rem;
}
.kunde-avatar .b-avatar-img img {
  object-fit: contain;
}
.btn-delete-contact-person {
  top: -0.5rem;
  right: -0.5rem;
}
</style>
